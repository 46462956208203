import React, { useEffect, useState } from "react";
import axios from "axios";
import { fetchData } from "../../api";
import styles from "./search_farm.module.css";
import { use } from "react";

const SearchFarmList = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataHeader, setTableDataHeader] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [xCoord, setXCoord] = useState("");
  const [yCoord, setYCoord] = useState("");
  const [user, setUser] = useState({});
  const [troops_for_farm, set_troops_for_farm] = useState([]);
  const [units, setUnits] = useState({
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
    t5: 0,
    t6: 0,
    t7: 0,
    t8: 0,
    t9: 0,
    t10: 0
  });

  const fetchDataUser = async (token) => {
        // Замените URL на ваш серверный адрес
        const getAllUsers = await fetchData("fetchGetUser",{token:token})
        setUser(getAllUsers)
        const updatedList = JSON.parse(getAllUsers.name_troops_farm_list).map(item => {
            return {
              ...item, // Сохраняем все остальные свойства объекта
              inputName: item.inputName.match(/\[t\d+\]/)[0].slice(1, -1) // Извлекаем "t1", "t2", и т.д.
            };
          });
          
          set_troops_for_farm(updatedList);
          
  }
  
  const fetchDataInactives = async (page, server, coordinates) => {
    try {
      const baseUrl = "https://www.inactivesearch.it/inactives/";
      const url = `${baseUrl}${server}`;
      const response = await axios.get(url, {
        params: { c: coordinates, page },
      });
  
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, "text/html");
  
      // Удаляем пустые <div> элементы
      const emptyDivs = doc.querySelectorAll("div:empty");
      emptyDivs.forEach((div) => div.remove());
  
      const table = doc.querySelector(
        ".table.table-condensed.table-inactives.table-shadow"
      );
  
      if (!table) {
        console.error("Таблица не найдена");
        setTableData([]);
        return;
      }
  
      const headers = Array.from(table.querySelectorAll("thead th"))
        .map((header) => header.textContent.trim())
        .filter((header) => header && /^\d{2}-\d{2}$/.test(header));
  
      const rows = table.querySelectorAll("tbody tr");
      const data = Array.from(rows).map((row) => {
        const cells = row.querySelectorAll("td");
        return Array.from(cells)
          .slice(0, -2)
          .map((cell) => cell.textContent.trim());
      });
  
      const pagination = doc.querySelector(".pull-right span");
      const [current, total] =
        pagination?.textContent.split("/").map(Number) || [1, 1];
      setTotalPages(total);
  
      setTableDataHeader(headers);
      setTableData(data);
      setLoading(false);
    } catch (error) {
      console.error("Ошибка при получении данных из таблицы:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    // Установим первый ID в качестве значения по умолчанию
    if (user.id_raid_list) {
      const defaultValue = user.id_raid_list.split(",")[0];
      setSelectValue(defaultValue);
    }
  }, [user.id_raid_list]);
  useEffect(() => {
    fetchDataUser(localStorage.getItem('token_Account_bot'))
  }, []);
  useEffect(() => {
    if(user.server){
      const cleanedUrl = user.server.replace(/^https?:\/\//, "");
      let coordinates = `(${xCoord}|${yCoord})`
      fetchDataInactives (currentPage,cleanedUrl,coordinates);
    }
  }, [currentPage]);

  const handleRowClick = (row) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((r) => r !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData);
    }
  };

  const handleAdd = async () => {
    const cleanedUrl = user.server.replace(/^https?:\/\//, "");
    let coordinates = `(${xCoord}|${yCoord})`
    fetchDataInactives (currentPage,cleanedUrl,coordinates);
    // const dataToSend = {
    //   selectValue,
    //   x: xCoord,
    //   y: yCoord,
    //   selectedRows,
    // };

    // try {
    //   // Замените URL на ваш серверный адрес
    //   const url = "https://your-server-address.com/add";
    //   await axios.post(url, dataToSend);
    //   alert("Данные успешно отправлены");
    // } catch (error) {
    //   console.error("Ошибка при отправке данных:", error);
    // }
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!tableData.length) {
//     return <div>Not Data</div>;
//   }
  // Обработчик изменения значений
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Обновляем только изменённое значение
    setUnits((prevUnits) => ({
      ...prevUnits,
      [name]: value === "" ? 0 : parseInt(value, 10) || 0 // Если пусто, то 0
    }));
  };

  const add_to_farm_list_fetch = async () => {
    // console.log({id_raid_list:selectValue})
    // console.log({units:units})
    const hasUnits = Object.values(units).some((value) => value > 0);

    if (!hasUnits) {
      alert("count troops");
      return;
    }
    const formateTargets = selectedRows.map((item,idx) => {
      const result = item[1].replace(/[()]/g, "");
      return {coordinates: result}
    })
    // console.log({formateTargets:formateTargets})
    
    const getAllUsers = await fetchData("addTargets",{token:localStorage.getItem('token_Account_bot'),id_raid_list:selectValue,units,formateTargets})
    if(getAllUsers){
      setSelectedRows([]);
      alert('target start add')
    }
    // const getAllUsers = await fetchData("addToFarmListTarget",{id_raid_list:selectValue})
  }

  const handlePageChange = (direction) => {
    setSelectedRows([]);
    setCurrentPage((prevPage) => {
      const newPage = prevPage + direction;
      return newPage >= 1 && newPage <= totalPages ? newPage : prevPage;
    });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
        <select
          className={styles.styleInput}
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
        >
          <option>{user.server}</option>
        </select>
        <input
          className={styles.styleInput}
          type="text"
          placeholder="x"
          value={xCoord}
          onChange={(e) => setXCoord(e.target.value)}
        />
        <input
          className={styles.styleInput}
          type="text"
          placeholder="y"
          value={yCoord}
          onChange={(e) => setYCoord(e.target.value)}
        />
        <button
          onClick={handleAdd}
          className={styles.styleInput}
        >
          Search
        </button>
      </div>
      <div>
      { tableData.length !== 0 && <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between"}}>
        <select
          className={styles.styleInput}
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
        >
          {user.farm_list.split(',').map((item, idx) => {
                return <option value={user.id_raid_list.split(',')[idx]}>{item}</option>
          })}
        </select>
        <button onClick={add_to_farm_list_fetch} className={styles.styleInputBtn}>
          Add to farm list
        </button>
        </div>}
        { tableData.length !== 0 && <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"space-around"}}>
       { troops_for_farm.map((item, idx) => {
        
            return (
              <div style={{display:'flex', flexDirection:"column", width:"50%"}}>
              <span>{item.troopName}</span>
                <input
                key={idx}
                className={styles.styleInputTroops}
                type="text"
                name={item.inputName}
                placeholder={item.troopName}
                value={units[item.inputName]}
                onChange={handleInputChange}
              /> 
              </div>
            )
        })}
            
        </div> }
        { tableData.length !== 0 && <button onClick={handleSelectAll} className={styles.styleInputBtn}>
          {selectedRows.length === tableData.length ? "Deselect" : "Select all"}
        </button> }
      </div>
      <div>

      </div>
      { tableData.length !== 0 && <div style={{ display: "flex", flexDirection: "row", width: "100%", backgroundColor: "black", color: "white", padding: 4 }}>
        {["DIS", "X/Y", "VIL", ...tableDataHeader].map((header, index) => (
          <div
            key={index}
            style={{
              flex: 1,
              textAlign: "center",
              padding: "5px 0",
            }}
          >
            {header}
          </div>
        ))}
      </div>}
      
      {tableData.length !== 0 && tableData.map((row, idx) => (
        <div
          onClick={() => handleRowClick(row)}
          key={idx}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            paddingBottom: 5,
            backgroundColor: selectedRows.includes(row) ? "#cce5ff" : "#fffbd5",
            padding: "10px 5px",
            borderBottom: "1px solid black",
            cursor: "pointer",
          }}
        >
          {row.map((cell, cellIdx) => (
            <div
              key={cellIdx}
              style={{
                textAlign: "center",
                marginRight:3
              }}
            >
                {/* {console.log(cell.split(' '))} */}
              {cell.split(' ').length === 17 ? `${cell.split(' ')[0]}` : cell }
            </div>
          ))}
        </div>
      ))}
      {tableData.length !== 0 && <div style={{ marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <button
          onClick={() => handlePageChange(-1)}
          disabled={currentPage === 1}
          style={{
            marginRight: 10,
            backgroundColor: "black",
            color: "white",
            border: "none",
            borderRadius: 5,
            padding: 15,
          }}
        >
          Prev
        </button>
        <span>
          Page {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(1)}
          disabled={currentPage === totalPages}
          style={{
            marginLeft: 10,
            padding: 15,
            backgroundColor: "black",
            color: "white",
            border: "none",
            borderRadius: 5,
          }}
        >
          Next
        </button>
      </div>}
    </div>
  );
};

export default SearchFarmList;
