import React,{useState,useContext,useEffect} from 'react';
import styles from './resourse.module.css'
import {fetchData, generateRandomToken}  from '../../api'
function ResourseFields({buildProps,token,selectVillage,deleteWaitBuildDB,resourseFields,openModelFunc}) {
    const [nowLevel, setNowLevel] = useState(Number(buildProps[0]))
    const [gidFields, setGidFields] = useState(Number(buildProps[1]))
    const [levelBuild, setLevelBuild] = useState(nowLevel)
    const [buildHouseStatus, setBuildHouseStatus] = useState([])
    const [selectResourseFastMenu, setSelectResourseFastMenu] = useState(null)
    const [typeResourseFields, setTypeResourseFields] = useState(Number(buildProps[1]))
    const [link, setLink] = useState(buildProps[2])
    
    const level = [1,2,3,4,5,6,7,8,9,10]
    const chooseLevelResourseFields = (buildLevel) => {
        setLevelBuild(level[buildLevel]) 
    }

    useEffect(() => {
        fetchResultBuild()
        
    },[])

    const clickFastMenuResourse = (buildProps, selectId) => {
        console.log(buildProps)
        setNowLevel(buildProps[0])
        setGidFields(Number(buildProps[1]))
        setSelectResourseFastMenu(selectId)
        setTypeResourseFields(buildProps[1])
        setLink(buildProps[2])
    }


    const fetchResultBuild = async () =>{
        const result = await fetchData("getBuildCenterVillageAndResourseFields",{village:selectVillage,token})
        // console.log('result', result)
        setBuildHouseStatus(result)
       
    }

    const fetchStartBuildResourseFields = async () => {
        setTypeResourseFields(buildProps[1])
        setLink(buildProps[2]);
        console.log({typeResourseFields,nowLevel,complete_level:levelBuild,token,village:selectVillage,link})
        const result_status = await fetchData("addHouseInBuild", {typeResourseFields,nowLevel,complete_level:levelBuild,token,village:selectVillage,link}) 
        if(result_status.status === "add_complete"){
            fetchResultBuild()
        } 
    }

  return (
    <div>
        <div className={styles.wrapperNameVillages}>
        {resourseFields.map((item,idx) => {
          const splitItem = item.split(',')
        //  console.log(splitItem)
          const level = splitItem[0]
          const gid = splitItem[1]
          const linkTravian = splitItem[2]

          switch (gid) {
            case '1':  
            return (
              <div key={idx} style={selectResourseFastMenu === idx ? { border: '1px solid black' } : {}} onClick={() => clickFastMenuResourse([level, gid,linkTravian],idx)} className={`${styles.boxResourse} ${styles.tree}`}><img className={styles.headerImg} width={15} height={12} src="/1.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>
            )
            case '2':
             return ( <div  style={selectResourseFastMenu === idx ? { border: '1px solid black' } : {}} onClick={() => clickFastMenuResourse([level, gid,linkTravian],idx)} key={idx} className={`${styles.boxResourse} ${styles.clay}`}><img className={styles.headerImg} width={15} height={12} src="/2.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>)
            case '3':
              return ( <div  style={selectResourseFastMenu === idx ? { border: '1px solid black' } : {}} onClick={() => clickFastMenuResourse([level, gid,linkTravian],idx)} key={idx}  className={`${styles.boxResourse} ${styles.iron}`}><img className={styles.headerImg} width={15} height={12} src="/3.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>)
            case '4':
              return ( <div  style={selectResourseFastMenu === idx ? { border: '1px solid black' } : {}} onClick={() => clickFastMenuResourse([level, gid,linkTravian],idx)} key={idx}  className={`${styles.boxResourse} ${styles.breed}`}><img className={styles.headerImg} width={15} height={12} src="/4.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>)
          
            default:
              return (<></>)
          }

        })}
        </div>
        <div style={{display:'flex', justifyContent:"space-around", alignItems:"center", marginBottom:10}}>
            <div style={{display:'inline-block',textAlign:'center',marginLeft:10}}>
                {level.map((item,idx) => {
                    if(nowLevel >= item){
                        return <div style={{color:'black',backgroundColor: "rgba(0,0,0,0.2)", fontSize:30, border:"0px solid black",borderRadius:1, padding:7}} key={idx}>{item}</div>
                    }else{
                        if(levelBuild === item) {
                            return <div onClick={() => chooseLevelResourseFields(idx)} style={{color:'black', fontSize:30, border:"1px solid black",borderRadius:1, padding:7}} key={idx}>{item}</div>
                        }else{
                            return <div onClick={() => chooseLevelResourseFields(idx)} style={{color:'black', fontSize:30, border:"0px solid black",borderRadius:1, padding:7}} key={idx}>{item}</div>
                        }
                        
                    }
                
                }).reverse()}
            </div>
            <div>
                <div className={styles.wrapperInfoBoxResourse}>
                {Number(gidFields) === 1 && (
                    <>
                    <img style={{position:"absolute"}}  width={15} height={12} src="/1.png" alt="" />
                    <div className={styles.tree} style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100%"}}>
                        <span style={{fontSize:28}}>{nowLevel}</span>
                        <img  width={25} height={22} src="/arrow.png" alt="" />
                        <span style={{fontSize:28}}>{levelBuild}</span>
                    </div>
                    </>
                )}
                {Number(gidFields) === 2 && (
                    <>
                    <img style={{position:"absolute"}}  width={15} height={12} src="/2.png" alt="" />
                    <div className={styles.clay} style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100%"}}>
                        <span style={{fontSize:28}}>{nowLevel}</span>
                        <img  width={25} height={22} src="/arrow.png" alt="" />
                        <span style={{fontSize:28}}>{levelBuild}</span>
                    </div>
                    </>
                )}
                {Number(gidFields) === 3 && (
                    <>
                    <img style={{position:"absolute"}}  width={15} height={12} src="/3.png" alt="" />
                    <div className={styles.iron} style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100%"}}>
                        <span style={{fontSize:28}}>{nowLevel}</span>
                        <img  width={25} height={22} src="/arrow.png" alt="" />
                        <span style={{fontSize:28}}>{levelBuild}</span>
                    </div>
                    </>
                )}
                {Number(gidFields) === 4 && (
                    <>
                    <img style={{position:"absolute"}}  width={15} height={12} src="/4.png" alt="" />
                    <div className={styles.breed} style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100%"}}>
                        <span style={{fontSize:28}}>{nowLevel}</span>
                        <img  width={25} height={22} src="/arrow.png" alt="" />
                        <span style={{fontSize:28}}>{levelBuild}</span>
                    </div>
                    </>
                )}

                </div>
                <button onClick={fetchStartBuildResourseFields} className={styles.startBuild}>Build</button>
            </div>
        </div>
        {buildHouseStatus.map((item,idx) => {
            // console.log(item)
            let img = ""
            if(item.type_field.length === 1){
                img = "/" + item.type_field + ".png"
            }
            return (
                <ul onClick={() => deleteWaitBuildDB(item.token, item.village, item.status, item.id)} key={idx} style={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center",border:"1px solid black", marginBottom:2,padding:8}}>
                    {item.type_field.length != 1 
                    ? <li style={{fontSize:18}}>{item.type_field}</li>
                    : <li><img  width={15} height={12} src={img} alt="" /></li>}
                    <li style={{fontSize:16}}>{item.village}</li>
                    <li style={{fontSize:20}}>{item.now_level}</li>
                    <li><img  width={25} height={22} src="/arrow.png" alt="" /></li>
                    <li style={{fontSize:20}}>{item.complete_level}</li>
                    {/* <li style={{fontSize:20}}>20min: 23sec</li> */}
                    <li>{item.status}</li>
            </ul> 
            )
        })}

        </div>

  );
}

export default ResourseFields;
