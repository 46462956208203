import React,{useState,useContext,useEffect} from 'react';
// import logo from './logo.svg';
import styles from './travianbot.module.css'
import {fetchData, generateRandomToken,millisToMinutesAndSeconds}  from '../api'
import Modal from './Modal';
import {
  Link
} from "react-router-dom";
// import {Context } from "../../context"
function TravianBot({setArrowBackStatus}) {
const [villages, setVillages] = useState([])
const [openModel, setOpenModel] = useState(false)
const [centerVillage, setCenterVillage] = useState([])
const [resourseFields, setResourseFields] = useState([])
const [typeOpenModal, setTypeOpenModal] = useState('')
const [buildProps, setBuildProps] = useState([])
const [selectVillage, setSelectVillage] = useState('')
const [token, setToken] = useState('')
const [status_connect_actions, set_status_connect_actions] = useState('')
const [time_next_build_db, set_time_next_build_db] = useState(0)
const [time_next_build_db_status, set_time_next_build_db_status] = useState(0)
const [fastMenu, setFastMenu] = useState(['Raids', 'Troops'])
const [selectfastMenu, setselectfastMenu] = useState('')
const [lengthFarmList, setLengthFarmList] = useState(0)

const openModelFunc = (typeModal, propsBuild) => {
  console.log(111111, typeModal,buildProps)
  setArrowBackStatus('/open');
  setTypeOpenModal(typeModal);
  setBuildProps(propsBuild);
  setOpenModel(true);
};


const getVillages = async() => {
  const fetchVillages = await fetchData("getUserVillages",{'token':localStorage.getItem("token_Account_bot")} )
  setVillages(fetchVillages)
  if(selectVillage !== ''){
    getVillagesAndResourseFields(selectVillage)
    if(localStorage.getItem("village") == null){
      localStorage.setItem('village', selectVillage)
    }
  }else{
    if(localStorage.getItem("village")){
      setSelectVillage(localStorage.getItem("village"))
      getVillagesAndResourseFields(localStorage.getItem("village"))
    }else{
      setSelectVillage(fetchVillages[0].village)
      getVillagesAndResourseFields(fetchVillages[0].village)
      localStorage.setItem('village', fetchVillages[0].village)
    }

  }
  
}
const getVillagesAndResourseFields = async(village) => {
  const fetchVillagesAndResourse = await fetchData("getResourseFieldsAndVillageCenter",{'token':localStorage.getItem("token_Account_bot"), 'village':village})
  // console.log("fetchVillagesAndResourse", fetchVillagesAndResourse)
  if(fetchVillagesAndResourse){
    if(JSON.parse(fetchVillagesAndResourse.center_village) !== null){
      const center_village = JSON.parse(fetchVillagesAndResourse.center_village).filter((item, idx) => {
        const arr = item.split(',')
        if(arr[1] !== ''){
          return item
        }
      })
      setCenterVillage(center_village)
    }else{
      setCenterVillage(JSON.parse(fetchVillagesAndResourse.center_village))
    }

    
    setResourseFields(JSON.parse(fetchVillagesAndResourse.resourse_fields))
    set_time_next_build_db(JSON.parse(fetchVillagesAndResourse.time_next_build_db))
    set_time_next_build_db_status(JSON.parse(fetchVillagesAndResourse.time_next_build))
    setToken(fetchVillagesAndResourse.token)
    
  }else{
    setCenterVillage(null)
    setResourseFields(null)
  }
}
    const getUser = async () => {
      const fetchGetUser = await fetchData("fetchGetUser",{'token':localStorage.getItem("token_Account_bot")})
      console.log(fetchGetUser)
      if(fetchGetUser.farm_list != null){
        setLengthFarmList(fetchGetUser.farm_list.length)
      }
      if(fetchGetUser){
        set_status_connect_actions(fetchGetUser.status_connect_actions)
      }
    }
    useEffect(() => {
        getVillages()
        getUser()
    },[])

 
    const changeVillage = (village) => {
      setSelectVillage(village)
      localStorage.setItem('village', village)
      getVillagesAndResourseFields(village)
    }
    
    const getCenterAndFields = async () => {
      // console.log(status_connect_actions)
      const getUserVillagesAddDB = await fetchData("getUserVillagesAddDB",{'token':localStorage.getItem("token_Account_bot"), "selectVillage":selectVillage} )
      if(getUserVillagesAddDB){
        getVillages()
        getUser()
      }
    }

    const startOrStopBuild = async (status) => {
      const startBuild = await fetchData("startOrStopBuild",{'token':localStorage.getItem("token_Account_bot"), "selectVillage":selectVillage,status} )
      // console.log('startBuild', startBuild)
      if(startBuild.status == 'start build'){
        getVillages()
        getUser()
      }else{
        alert('error, add build')
      }
    }

    const openRaidsPage = () => {

    }

  return (
    <>
    {openModel ?
    <Modal openModelFunc={openModelFunc} resourseFields={resourseFields} typeOpenModal={typeOpenModal} setTypeOpenModal={setTypeOpenModal} buildProps={buildProps} token={token} selectVillage={selectVillage}/>
     : 
     <>
    <div className={styles.wrapperNameVillages}>
      
   { villages.map((item, idx) => {
    if(selectVillage === item.village){
      return (
        <div style={{marginRight:10, border:"1px solid black", padding:10, color:'white', backgroundColor:"black"}} key={idx}>
          {item.village}
        </div>
      )
    }else{
      return (
        <div onClick={() => changeVillage(item.village)} style={{marginRight:10, border:"1px solid black", padding:10}} key={idx}>
          {item.village}
        </div>
      )
    }
    })}
    </div> 
      {!centerVillage && !resourseFields && status_connect_actions === "getResorseFieldsAndCenterVillage" 
      ?<div className={styles.wrapperBtnGetVillages}>Data search</div>
      :!centerVillage && !resourseFields && <div className={styles.wrapperBtnGetVillages}>Data not found<br/><button onClick={() => getCenterAndFields()} className={styles.btnGetVillages}>Get data</button></div>
    }
    {centerVillage && resourseFields &&<div style={{position:"absolute", top:136}}>
      <div style={{display:"flex", flexDirection:"row", marginTop:10, marginBottom:10}}>
               <div style={{width:"60%", border:"1px solid black", marginRight:10,display:"flex", justifyContent:"center", alignItems:"center"}}>
                
                {time_next_build_db_status === 0 ? "Timer not started" : time_next_build_db_status === 1 ? "Search..." : millisToMinutesAndSeconds(time_next_build_db)}
               </div>
                {time_next_build_db_status === 0 ? <button onClick={() => startOrStopBuild(1)}  className={styles.startRaidTimer}>Start</button> : <button onClick={() => startOrStopBuild(0)}  className={styles.stopRaidTimer}>Stop</button>}     
      </div>
      <h3 style={{textAlign:"center"}}>Resourse Fields</h3>
      <div className={styles.wrapperBoxResourse}>
        {resourseFields.map((item,idx) => {
          const splitItem = item.split(',')
          // console.log('splitItem', splitItem)
          const level = splitItem[0]
          const gid = splitItem[1]
          const link = splitItem[2]
          switch (gid) {
            case '1':  
            return (
              <div key={idx} onClick={() => openModelFunc('buildResourseFields',[level, gid,link],resourseFields)} className={`${styles.boxResourse} ${styles.tree}`}><img className={styles.headerImg} width={15} height={12} src="/1.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>
            )
            case '2':
             return ( <div key={idx} onClick={() => openModelFunc('buildResourseFields',[level, gid,link],resourseFields)} className={`${styles.boxResourse} ${styles.clay}`}><img className={styles.headerImg} width={15} height={12} src="/2.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>)
            case '3':
              return ( <div key={idx} onClick={() => openModelFunc('buildResourseFields',[level, gid,link], resourseFields)} className={`${styles.boxResourse} ${styles.iron}`}><img className={styles.headerImg} width={15} height={12} src="/3.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>)
            case '4':
              return ( <div key={idx} onClick={() => openModelFunc('buildResourseFields',[level, gid,link], resourseFields)} className={`${styles.boxResourse} ${styles.breed}`}><img className={styles.headerImg} width={15} height={12} src="/4.png" alt="" /><div className={styles.levelResourseInBox}>{level}</div></div>)
          
            default:
              return (<></>)
          }

        })}
      </div>
      <h3 style={{textAlign:"center"}}>Center Village</h3>
      <div className={styles.wrapperBoxCenterVillge}>
        {centerVillage.map((item,idx) => {
          const splitItem = item.split(',')
          
          const gid = splitItem[0]
          const name = splitItem[1]
          const level = splitItem[2]
          const link = splitItem[3]
          
            // case '16':
            //   return (<div key={idx} onClick={() => openModelFunc('modalRaidorBuild',[level, name, link])} className={`${styles.boxResourse} ${styles.houses}`}><div className={styles.levelCenterVillageBox}><div>{name}</div><div>{level}</div></div></div>)
            // case '19':
            //   return (<div key={idx} onClick={() => openModelFunc('modalRaidorBuild',[level, name, link])} className={`${styles.boxResourse} ${styles.houses}`}><div className={styles.levelCenterVillageBox}><div>{name}</div><div>{level}</div></div></div>)
            // default:
              return (<div key={idx} onClick={() => openModelFunc('buildcenterVillage',[level, name, link])} className={`${styles.boxResourse} ${styles.houses}`}><div className={styles.levelCenterVillageBox}><div style={{textAlign:"center"}}>{name}</div><div>{level}</div></div></div>)
          
          
        })}
        
      </div>
      {/* <div style={{position:"fixed", zIndex:100, backgroundColor:"#faeded", bottom:0, color:"white", width:"100%"}}> */}
      <h3 style={{textAlign:"center"}}>BOT MENU</h3>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around",alignItems:"center", height:70,marginBottom:10}}>
            <div style={{height:70,width:"33%", lineHeight:"70px", textAlign:"center",backgroundColor:" #e6eef9"}}><Link to="/raids">RAIDS</Link></div>
            {lengthFarmList !== 0 && <div style={{height:70,width:"33%", lineHeight:"70px", textAlign:"center",backgroundColor:" #e6eef9"}}  for="radio-2"><Link to="/searchFarmList">SEARCH</Link></div>}
            <div style={{height:70,width:"33%", lineHeight:"70px", textAlign:"center",backgroundColor:" #e6eef9"}} for="radio-3"><Link to="/troops">TROOPS</Link></div>
          </div>
        
        {/* </div> */}
        {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around",  alignItems:"center", height:50}}>
          <Link to="/raids" style={{width:"100%",height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", border:'1px solid black'}}><img src="https://cdn.legends.travian.com/gpack/2500.6/img_ltr/global/combat/attack_small.png" width={22} height={22} alt="" srcset="" /></Link>
          <Link to="/searchFarmList" style={{width:"100%",height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", border:'1px solid black'}}><img src="/search_farm_list.svg" width={22} height={22} alt="" srcset="" /></Link>
          <Link to="/troops" style={{width:"100%",height:"100%",display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", border:'1px solid black'}}><img src="https://cdn.legends.travian.com/gpack/2500.6/img_ltr/legacy/views/buildings/upgradeBuilding/buildingBonus/infantryBonusTime_medium.png" alt="" srcset="" /></Link>
        </div> */}
      
    </div>}
     </>
     }
    

    </>
  );
}

export default TravianBot;